<template>
    <v-container fluid>
        <PageHeaderSlot :isLoading="$store.getters.isLoading"> </PageHeaderSlot>
        <v-row>
            <v-col cols="12">
                <v-btn depressed color="primary accent-4" class="white--text" :to="{ name: 'CreateNews' }"> 新增 </v-btn>
            </v-col>
            <v-col cols="12">
                <Datatable 
                    :isLoading="tableLoading" 
                    :tableHeaders="tableHeaders" 
                    :tableData="tableData" 
                    :page="tablePage"
                    :pageLimit="tableLimit" 
                    :itemTotal="tableItemTotal" 
                    @options-update="onTableOptionsChange($event)"
                    @delete-clicked="handleDelete($event)" 
                    actionViewRouteLink="NewsDetails"
                    enableSort
                ></Datatable>
            </v-col>
        </v-row>

        <ConfirmDialog ref="deleteDialog"></ConfirmDialog>
    </v-container>
</template>
  
<script>
import { mapActions } from 'vuex';
import Datatable from '@/components/Datatable.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
    name: 'NewsList',
    components: {
        Datatable,
        ConfirmDialog
    },
    data: () => ({
        tableLoading: false,
        tableData: [],
        tablePage: 1,
        tableLimit: 10,
        tableItemTotal: 0,
        tableSortKey: 'create_date',
        tableSortDir: 'desc',

        // --- static data
        tableHeaders: [
            { text: '建立日期', value: 'create_date', width: 150 },
            { text: '發佈日期', value: 'publish_date', width: 150 },
            { text: '標題', value: 'title', sortable: false },
            { text: 'URL Slug', value: 'slug', sortable: false },
            { text: '', value: 'actionViewDelete', width: 240, sortable: false },
        ],
    }),

    methods: {
        ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
        async getNewsData() {
            this.tableLoading = true;
            this.tableData = [];
            this.tableItemTotal = 0;
            try {
                let payload = {
                    limit: this.tableLimit,
                    page: this.tablePage - 1
                };

                if (this.tableSortKey !== '' && this.tableSortDir !== '') {
                    payload['sorting'] = this.tableSortKey;
                    payload['direction'] = this.tableSortDir;
                }

                const { data, total } = await this.$Fetcher.GetNews(payload);
                this.tableItemTotal = total;
                this.tableData = data.map(item => {
                    return {
                        id: item.id,
                        create_date: item.create_date,
                        title: item.title,
                        publish_date: item.publish_date,
                        slug: item.slug
                    }
                })
            } catch (err) {
                this.$common.error(err);
            } finally {
                this.tableLoading = false;
            }
        },

        onTableOptionsChange(options) {
            if (options.itemsPerPage !== this.tableLimit) {
                this.tablePage = 1; // reset to first page if view options changed
            } else {
                this.tablePage = options.page;
            }

            this.tableLimit = options.itemsPerPage;
            if (options.sortBy.length > 0) {
                this.tableSortKey = options.sortBy[0];
            } else {
                this.tableSortKey = '';
            }

            if (options.sortDesc.length > 0) {
                if (options.sortDesc[0] === true) {
                    this.tableSortDir = 'desc';
                } else {
                    this.tableSortDir = 'asc';
                }
            } else {
                this.tableSortDir = '';
            }
            this.getNewsData();
        },

        async handleDelete(id) {
            if (this.$store.getters.isLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            const confirm = await this.$refs['deleteDialog'].show();
            if (confirm === true) {
                this.$store.dispatch('setLoading', true);
                try {
                    await this.$Fetcher.DeleteNewsById(id);
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '刪除成功',
                        type: 'success',
                        refresh: false,
                        redirect: ''
                    });

                    this.getNewsData();
                } catch (err) {
                    this.setDialogMessage({
                        title: "刪除失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }
            }
        },
    },
    async created() {
        this.getNewsData();
    },

    // ------ navigation guard ------
    beforeRouteLeave(to, from, next) {
        if (this.$store.getters.isLoading) {
            this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'processing',
                type: 'error',
                refresh: false,
                redirect: ''
            });
            next(false);
        } else if (this.$store.getters.dataIsUpdated) {
            this.setLeaveDialogLink({ name: to.name });
            this.setShowLeaveDialog(true);
            next(false);
        } else {
            next();
        }
    },
}
</script>
  